@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

    li {
        @apply px-4;
        @apply cursor-pointer
    }
}